@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-image: url('./assets/images/background.jpg');
  background-size: cover; /* Cover the entire viewport */
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  background-attachment: fixed; /* Keep the background fixed */
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 641px) and (max-width: 1280px) {
  body {
    background-position: center;
  }
}

@media (max-width: 640px) {
  body {
    background-position: center;
    min-height: 100vh;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
